import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

import AppLayout from "../../components/shared/AppLayout";
import successIcon from "../../assets/images/success-icon.svg";

const PageCustomerCollectionSuccess = () => {
    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} isBackBtn={false} hasFooter={false}>

            <div className="app-page page-customer-collection-success">

                <div className="customer-collection-success">

                    <div className="container">
                        <div className="customer-collection-success__header">
                            <h2 className="customer-collection-success__status">
                                <img
                                    className="customer-collection-success__status-icon img-fluid"
                                    src={successIcon}
                                    alt="icon"
                                    width="44"
                                />
                                <span className="customer-collection-success__status-text text-bold">
                                    <FormattedMessage id="customerCollection.success.title" />
                                </span>
                            </h2>
                        </div>
                    </div>

                </div>

            </div>

        </AppLayout>
    );
};

export default PageCustomerCollectionSuccess;
